






































import { Component, Prop, Vue } from "vue-property-decorator";
import { IStrDetailValue } from "@/Services/Interfaces";
import { InfoType, CrudType } from "@/Services/enums";
import { globalUpdateDetailService } from "@/Services/GlobalService";
import BaseSubComponent from "./BaseSubComponent.vue";
import ActionComponent from "./ActionComponent.vue";

@Component({ components: { ActionComponent, BaseSubComponent } })
export default class MemoComponent extends BaseSubComponent {
  labelcols: number;
  textarearows: number;
  thisshowLabel: boolean;
  thismodel: IStrDetailValue;

  constructor() {
    super();
    this.name = "MemoComponent";
    this.labelcols = 2;
    this.textarearows = 2;
    this.thisshowLabel = true;
    this.thismodel = <IStrDetailValue>{};
  }

  name: string;

  @Prop()
  showLabel: boolean|undefined;


  setlabelsize(rowcols: any) : void
  {
    if (!rowcols) return;
    this.labelcols = rowcols.cols;
    if (rowcols.rows)
    {
      this.textarearows = this.textarearows * rowcols.rows;
    }
  }


  onUpdate(model: any) {
    if (this.canUpdate(model)) {
      {
        this.thismodel.crud = CrudType.updatememovalue;
        globalUpdateDetailService.sendMessage(this.thismodel);
      }
    }
  }

  data() {
    return {
      thismodel: this.model,
      labelcols: 2,
      thisshowLabel: ( this.showLabel ? this.showLabel : true)
    };
  }

  computed() {}
}
