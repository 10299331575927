












































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IStrDetailValue } from '@/Services/Interfaces';
import { globalUpdateDetailService } from '@/Services/GlobalService';
import { InfoType, CrudType } from '@/Services/enums';
import ActionComponent from './ActionComponent.vue';
import BaseSubComponent from './BaseSubComponent.vue';
import MemoComponent from './MemoComponent.vue';

@Component({ components: { ActionComponent, BaseSubComponent, MemoComponent } })
export default class TextComponent extends BaseSubComponent {
  thismodel: IStrDetailValue = <IStrDetailValue>{};
  constructor() {
    super();
    this.name = 'TextComponent';
    this.canAction = false;
  }

  hasFocus(model : IStrDetailValue) : boolean {
    if (!model) return false;
    return model.page == 1 && model.pagesection == 1;
  }

  onUpdate(model: any) {
    if (this.canUpdate(model)) {
      this.thismodel.crud = CrudType.updatestrvalue;
      globalUpdateDetailService.sendMessage(this.thismodel);
    }
  }

  public data() {
    return {
      thismodel: this.model,
      canAction: false,
    };
  }

  computed() {}
}

